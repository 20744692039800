import * as React from "react";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import { DynamicContactFormThanks } from "../../components/sections";
import { LANG_FR_FR } from "../../constants";

const ContactPage = props => {
  return (
    <>
      <Seo title="Demander une démo" />
      <Header />
      <DynamicContactFormThanks locale={LANG_FR_FR} />
      <Footer />
    </>
  );
};

export default ContactPage;
